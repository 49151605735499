@import './app/styles/colors';
@import './app/styles/fonts';

.fade.in {
    opacity: 1;
}

.modal.in .modal-dialog {
    z-index: 1000000;
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-backdrop .fade .in {
    opacity: 0.5 !important;
}


.modal-backdrop.fade {
    opacity: 0.5 !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

header {
    padding: 5px;
   
    .heading {
        background-color: $normal-border;
        padding: 10px;
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
}

.main-content {
    padding: 5px;
    color: $normal-font-color;

    h2 {
        text-align: center;
    }
}

.sub-content {
    display: flex;
    flex-direction: row;
    padding: 10px;

    .sub-section {
        .form-fields {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
        }
    }

    @media screen and (max-width: 998px) {
        flex-direction: column;
        padding: 0;
    }
}

.button-group {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    button {
        margin-right: 5px;
    }
}

.section-wrapper {
    border: solid 1px $normal-border;
    border-radius: 8px;
    padding: 15px;
}

.sub-section {
    width: 100%;
    margin-right: 5px;

    h3 {
        text-align: center;
    }

    @media screen and (max-width: 998px) {
        margin-right: 0;
        margin-bottom: 5px;
    }
}

.card-wrapper {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h3 {
        font-weight: 700;
    }

    @media screen and (max-width: 998px) {
       min-height: 350px;
    }
}

.fields {
    flex-direction: column;
    padding: 2px 2px;

    small {
        color: $error;
        font-size: $font-extra-small;
        padding: 4px;
    }
}

.fields, .fields-with-value {
    display: flex;
    color: $normal-font-color;
    width: 100%;

    label, span, a, p {
        font-size: $font-small;
    }

    label {
        font-weight: bold;
        padding: 4px;
        white-space: nowrap;
    }

    span, a {
        padding: 4px 5px;
        white-space: nowrap;
    }

    p {
        padding: 2px 5px;
        margin: 0;
    }
}

.fields-with-value {
    flex-direction: row;
    padding: 2px 2px;
}

.same-line-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pull-right {
    float: right;
}

.card-main-content {
    flex: 1;
}

.card-sub-content {
    flex: 2;
}

.success-message {
    padding: 15px;
    text-align: center;
    font-size: $font-large;
    font-weight: 500;
    color: $cinch-title;
    min-height: 530px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        background-color: $success-background;
        border-radius: 6px;
        padding: 15px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
}

.err-msg {
    background-color: $error-background;
    color: $error;
}

.sucs-msg {
    background-color: $success-background;
    color: $cinch-title;

    button {
        &:hover {
            border: solid 1px $success-highlight !important;
            border-radius: 8px;
            background-color: $success !important;
            color: white;
        }
    }
}

.mat-flat-button {
    &:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    background-color: $success;
    width: 140px;
    height: 40px;
}

.mat-form-field {
    width: 300px;
    &.mat-focused .mat-form-field-ripple {
        background-color: $success;
    }

    &.mat-focused .mat-form-field-label {
        color: $normal-font-color;
        font-size: $font-normal;
    }
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: $success;
}

.mat-snack-bar-container {
    position: relative;
    top: 120px;
    font-weight: 590;

    .mat-simple-snackbar-action {
        color: inherit;
        font-weight: 700;

        button {
            background-color: $normal-shadow;
            border-radius: 8px;

            &:hover {
                border: solid 1px $error-highlight;
                border-radius: 8px;
                background-color: $error;
                color: white;
            }
        }
    }

    @media screen and (max-width: 998px) {
        top: 136px;
    }
}

.mat-checkbox {
    position: relative;
    top: 3px;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $success-highlight;
}

.mat-table {
    thead {
        tr {
            background-color: $normal-shadow;
            th {
                font-weight: bold;
            }
           
        }
    }
}

.mat-radio-button {
    margin-left: 10px;
}
