/* color variables */
$card-background: #F6F6F6;
$cinch-title: rgb(56, 118, 29);

$disabled: #eee;

$error: #c81414;
$error-background: #fad2d2;
$error-highlight: #aa1212;

$normal-background: #fff;
$normal-border: #ccc;
$normal-font-color: #686d77;
$normal-shadow: rgba(64,60,67,.16);
$normal-title:#000;

$overlay: rgba(0, 0, 0, 0.4);

$success: #28d050;
$success-background: rgb(191, 240, 203);
$success-highlight: #1da53c;